import React from 'react';
import { graphql } from 'gatsby';
import ContactLayout from '../../layouts/contact-layout';
import SEO from 'components/seo';
import CityContactData from 'components/contact/city-contact-data';

const Warszawa = ({ data })=> {
  return <>
    <SEO title="Kontakt" />
    <ContactLayout>
      <CityContactData data={data} />
    </ContactLayout>
  </>;
};

export const cityQuery = graphql`
  query warsawPageQuery {
    allContactJson(filter: {city: {eq: "Warszawa"}}) {
      edges {
        node {
          id
          city
          contact {
            id
            post_code
            street
            title
          }
        }
      }
    }
  }
`;

export default Warszawa;
