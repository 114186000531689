import React from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
`;

const CityContactData = ({ data })=> {
  return <>
    {data.allContactJson.edges.map(({ node: { contact }})=> {
      return contact.map(({ id, email, phone, post_code: postCode, street, title })=> {
        return <StyledP key={title + id}>{title} <br /> {street && street || phone && phone} <br /> {postCode && postCode || email && email} </StyledP>;
      });
    })}
  </>;
};

export default CityContactData;
