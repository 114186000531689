import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Section, Container, Col75, Aside, Col33 } from 'components/misc';
import activeColor from '../style/active-color';

const StyledContactLayout = styled(Section)`

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    display: none;
  }
`;

const FlexContainer = styled(Container)`
  display: flex;
`;

const StyledAside = styled(Aside)`
  > p {
    display: none;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const FlexCol75 = styled(Col75)`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    display: block;
  }
`;

const StyleListItem = styled.li`
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

const MobileView = styled.div`
  display: none;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    display: block;
  }
`;

const ContactWrapper = styled.div`
  h2 {
    margin: 32px 0;
    font-size: 2.25rem;
  }

  &:first-of-type {
    > h2 {
      margin-top: 0;
    }
  }
`;

const StyledP = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
`;

const ContactLayout = ({ children, className })=> {
  const data = useStaticQuery(graphql`{
    allContact:  allContactJson(sort: {fields: id}) {
      nodes {
        id
        city
        path
        contact {
          id
          title
          street
          post_code
        }
      }
    }
  }`);

  return <>
    <StyledContactLayout topSection className={className}>
      <FlexContainer>
        <StyledAside title="Kontakt" withTitleAndShortcut>
          <ul>
            {data.allContact.nodes.map(({ city, path })=> {
              return <li key={city}><Link to={path} activeStyle={activeColor}>{city}</Link></li>;
            })}
          </ul>
        </StyledAside>
        <FlexCol75>
          <Col33>
            <ul>
              {data.allContact.nodes.map(({ city, path })=> {
                return <StyleListItem key={city}>
                  <Link to={path} activeStyle={activeColor}>{city}</Link>
                </StyleListItem>;
              })}
            </ul>
          </Col33>
          <Col33>
            {children}
          </Col33>
        </FlexCol75>
      </FlexContainer>
    </StyledContactLayout>
    <MobileView>
      <Section topSection>
        {data.allContact.nodes.map(({ city, contact })=> {
          return <ContactWrapper key={city}>
            <h2>{city}</h2>
            {contact.map(({ id, post_code: postCode, street, title })=> {
              return <StyledP key={title + id}>{title} <br /> {street} <br /> {postCode}</StyledP>;
            })}
          </ContactWrapper>;
        })}
      </Section>
    </MobileView>
  </>;
};

export default ContactLayout;
